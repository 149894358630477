import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
          John is great!!!
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
