import { useState } from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import FadeInOnScroll from "../components/FadeInOnScroll";
import CallToAction from "../components/CallToAction";
import IntroBand from "../components/IntroBand";
import GetStartedPopup from "../components/GetStartedPopup";

const CallNowBox = styled.div`
  max-width: 300px;
  height: auto;
  background: #2f3630;
  border-radius: 10px;
  text-align: left;
  padding: 40px 30px;
  font-family: "Open Sans", sans-serif;
  margin-top: 60px;

  @media screen and (max-width: 1000px) {
    width: 100%; /* Make it full width on smaller screens */
    margin-top: 10px;
    margin-bottom: 30px;
  }
`;

const CallNumber = styled.a`
  color: #b8d5aa;
  font-size: 30px;
  font-weight: 400;
  line-height: 1;

  position: relative;
  padding-right:  30px;
  cursor: pointer;
  text-decoration: none;

  &::after {
    padding-bottom:  15px;
    content: url("../images/arrow.svg");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: right ease 0.4s;
  }

  &:hover {
    opacity: 0.8;
    &::after {
      right: -10px;
    }
  }
  &:active {
    opacity: 0.5;
  }
`;


const CallNowText = styled.div`
  font-size: 24px;
  line-height: 1;
  color: #ffffff;
  padding: 5px;
`;

const ArrowLink = styled.a`
  color: #5e8a64;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;

  position: relative;
  padding-right: 40px;
  cursor: pointer;
  text-decoration: none;

  &::after {
    content: url("../images/arrow.svg");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: right ease 0.4s;
  }

  &:hover {
    opacity: 0.8;
    &::after {
      right: -10px;
    }
  }
  &:active {
    opacity: 0.5;
  }
`;

const ArrowLinkCircle = styled.a`
  cursor: pointer;

  img {
    transition: transform ease 0.4s;

    &:hover {
      opacity: 0.8;
      transform: scale(1.04);
    }
    &:active {
      opacity: 0.5;
      transition: none;
      transform: scale(1);
    }
  }
`;

const Hero = styled.div`
  #hero {
    padding: 40px 30px 20px;
    position: relative;
  }

  @media screen and (max-width: 970px) {
    #hero {
      padding: 50px 30px;
      position: relative;
    }
  }

  #hero #hero-graphic {
    position: absolute;
    right: 0;
    bottom: -20px;
    width: 724px;
  }

  @media screen and (max-width: 1200px) {
    #hero #hero-graphic {
      right: 30px;
      bottom: 73px;
      width: 50%;
    }
  }

  @media screen and (max-width: 970px) {
    #hero #hero-graphic {
      position: relative;
      right: unset;
      bottom: unset;
      max-width: 400px;
      width: 90%;
      display: block;
      margin: auto;
    }
  }

  #hero .wrap {
    width: 1184px;
    margin: auto;
    position: relative;
    z-index: 2;
  }

  #hero .inner-wrap {
    flex-direction: column;
    display: flex;
    width: 474px;
  }

  @media screen and (max-width: 970px) {
    #hero .inner-wrap {
      align-items: center;
      text-align: center;
      margin: 0 auto 50px;
    }
  }

  #hero .inner-wrap p {
    margin: 10px 0;
  }

  @media screen and (max-width: 1050px) {
    #hero .inner-wrap p {
      width: 400px;
      max-width: 100%;
    }
  }
`;

const TriColumnPhotos = styled.div`
  .tri-photo-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 100px 30px 70px;
  }

  .tri-photo-column .item {
    width: 319px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px;
  }
  .tri-photo-column .item img {
    width: 100%;
  }
  .tri-photo-column .item p {
    color: #2f3630;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.27;
    width: 269px;
    margin-top: 25px;
  }
`;

const ImproveWellbeing = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1184px;
  padding: 0 30px;

  img {
    width: 447px;
  }

  .text-block {
    width: 652px;
  }

  .text-block p {
    margin: 30px 30px 30px 0;
  }

  @media screen and (max-width: 970px) {
    flex-direction: column;
    text-align: center;
    flex-direction: column-reverse;

    img {
      margin: 0 auto 50px;
      max-width: 90%;
    }

    .text-block p {
      margin: 30px auto;
    }
  }
`;

const TrustedProfessionals = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1184px;
  padding: 0 30px;

  img {
    width: 466px;
    margin-right: 60px;
  }

  .text-block {
    width: 654px;
  }

  .text-block p {
    margin: 30px 30px 30px 0;
  }

  @media screen and (max-width: 970px) {
    flex-direction: column;
    text-align: center;

    img {
      margin: 0 auto 50px;
      max-width: 90%;
    }

    .text-block p {
      margin: 30px auto;
    }
  }
`;

const TriColumnIcons = styled.div`
  .tri-icon-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 90px 30px 50px;
  }

  .tri-icon-column .item {
    width: 317px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 50px;
  }

  .tri-icon-column .item img {
    margin-bottom: 30px;
  }

  .tri-icon-column .item h3 {
    color: #5e8a64;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
  }

  .tri-icon-column .item p {
    color: #2f3630;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    margin: 30px 0;
  }
`;

function Home() {

  const [menuOpen, setMenuOpen] = useState(false);
  const [getStartedOpen, setGetStartedOpen] = useState(false);

  const handleGetStartedClick = () => {
    setGetStartedOpen(true);
    setMenuOpen(false);
  };

  return (
    <FadeInOnScroll>
      <div className="green-bg">
        <Nav />
        {getStartedOpen && (
          <GetStartedPopup onClose={() => setGetStartedOpen(false)} />
        )}
      </div>
      
      <main>
        <Hero className="green-bg">
          <section id="hero">
            <div className="wrap">
              <div className="inner-wrap">
                <h1>Mental Health Counseling</h1>
                <p>During normal business hours, a person will answer.<br/>Please give us a call. We are always taking new clients.</p>
                <CallNowBox>
                  <CallNowText>{"Oregon"}</CallNowText>
                  <CallNumber href="tel:+15418180009">(541) 818-0009</CallNumber>
                  <br/><br/>
                  <CallNowText>{"Utah"}</CallNowText>
                  <CallNumber href="tel:+13855180652">(385) 518-0652</CallNumber>
                  <br/><br/>
                  <CallNowText>{"Kansas"}</CallNowText>
                  <CallNumber href="tel:+19132257850">(913) 225-7850</CallNumber>
                </CallNowBox>
                  { /* <button onClick={handleGetStartedClick}>Start here</button> */}
              </div>
            </div>
            <img
              id="hero-graphic"
              className="scroll-fade-in"
              src="images/hero-graphic.svg"
              alt="zen meditation graphic"
            />
          </section>
        </Hero>
        <IntroBand>
          Our VISION is a mindful and supportive community–of clients,
          counselors and support staff–who help each other become their best
          self.
        </IntroBand>
        <TriColumnPhotos className="pattern-bg">
          <section className="tri-photo-column">
            <div className="item scroll-fade-in delay-1">
              <img
                src="images/woman-pondering-in-office.png"
                alt="Woman pondering while sitting in an office setting"
              />
              <p>Individual Therapy</p>
            </div>
            <div className="item scroll-fade-in delay-2">
              <img
                src="images/hands-clasped-in-support.png"
                alt="Close-up of two people's hands clasped in a supportive gesture"
              />
              <p>Group Therapy</p>
            </div>
            <div className="item scroll-fade-in delay-3">
              <img
                src="images/close-up-hands-sharing-knitted-garment.png"
                alt="Close-up of two people's hands sharing a knitted garment"
              />
              <p>Family Therapy</p>
            </div>
          </section>
        </TriColumnPhotos>
        <div className="gap"></div>
        <ImproveWellbeing id="improve-wellbeing" className="scroll-fade-in">
          <div className="text-block">
            <h2>Counseling Services</h2>
            <p>
              We offer telehealth and in-person counseling using many different therapies: EMDR, DBT, CBT, Narrative,
              Solution-Focused, Client-Centered, Motivational-Interviewing, and
              more.
              <br /><br />
              You can filter and search by our counselor's therapies, expertise, and specialities. Click below to meet them.
            </p>
            <ArrowLink href="/counselors">Meet our Counselors</ArrowLink>
          </div>
          <img
            src="images/two-women-conversing-in-office.png"
            alt="Two women having a conversation in an office environment"
          />
        </ImproveWellbeing>
        <div className="gap-sm"></div>
        <TrustedProfessionals
          id="trusted-professionals"
          className="scroll-fade-in">
          <img
            src="images/woman-enjoying-sunlight-outdoors.png"
            alt="Woman smiling and enjoying sunlight outdoors"
          />
          <div className="text-block">
            <h2>Trusted Professionals</h2>
            <p>
              Our team of support staff is here to help you and your counselor. You can call us or fill out an email form.<br/><br/>
              <ArrowLink href="/staff">Meet our Staff</ArrowLink><br/><br/>
              During normal business hours, one of our staff will answer the
              phone 95% of the time. If we miss your call, we usually respond
              within 1 day but always within 2 business days.<br/><br/>
              <ArrowLink href="/Contact">Contact Us</ArrowLink>
            </p>
          </div>
        </TrustedProfessionals>
        <div className="gap"></div>
        <TriColumnIcons className="light-green-bg">
          <section className="tri-icon-column">
            <div className="item scroll-fade-in delay-1">
              <img
                src="images/mental-wellbeing-icon.svg"
                alt="Icon representing mental well-being with a head silhouette and a heart inside"
              />
              <h3>Taking New Clients</h3>
              <p>
                Our business strategy is to hire 2 new counselors each month, so
                that we are always taking new clients.
              </p>
              {/* <ArrowLinkCircle>
                <img src="images/arrow-button.svg" alt="arrow button" />
              </ArrowLinkCircle> */}
            </div>
            <div className="item scroll-fade-in delay-2">
              <img
                src="images/mindfulness-meditation-icon.svg"
                alt="Icon depicting mindfulness meditation with a figure in a lotus position"
              />
              <h3>We Accept Medicaid</h3>
              <p>
                Mental health services can be expensive, and we want everyone to
                have access to these services.
              </p>
              {/* <ArrowLinkCircle>
                <img src="images/arrow-button.svg" alt="arrow button" />
              </ArrowLinkCircle> */}
            </div>
            <div className="item scroll-fade-in delay-3">
              <img
                src="images/support-and-comfort-icon.svg"
                alt="Icon showing support and comfort with one figure embracing another"
              />
              <h3>We Are Diverse</h3>
              <p>
                We offer counseling services in Spanish, French, Farsi, and Mandarin in
                addition to English.
              </p>
              {/* <ArrowLinkCircle>
                <img src="images/arrow-button.svg" alt="arrow button" />
              </ArrowLinkCircle> */}
            </div>
          </section>
        </TriColumnIcons>

        <CallToAction />
      </main>
      <Footer />
    </FadeInOnScroll>
  );
}

export default Home;
