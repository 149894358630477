import React, { useState } from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import FadeInOnScroll from "../components/FadeInOnScroll";
import CallToAction from "../components/CallToAction";
import IntroBand from "../components/IntroBand";

const Hero = styled.div`
  z-index: 3;
  position: relative;

  nav {
    z-index: 3;
    position: relative;
  }
`;

const Title = styled.div`
  font-family: Raleway;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  color: black;
`;

const Description = styled.div`
  font-family: Raleway;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  padding: 10px 0;
  color: black;
`;

const ContactLayout = styled.div`
  display: flex;
  padding: 30px 30px 0;
  position: relative;
  flex-direction: row;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  width: 1300px;
  margin-bottom: -50px;

  @media screen and (max-width: 1000px) {
    padding: 50px 30px 0;
    margin-bottom: -20px;
    width: 700px;
    max-width: 100%;
    position: relative;
    flex-direction: column;
  }
`;

const ContactLeft = styled.div`
  width: 492px;
  max-width: 100%;
  flex-direction: column;
  padding-right: 70px;
  position: relative;

  @media screen and (max-width: 1000px) {
    width: 100%; /* Make it full width on smaller screens */
    margin-top: 20px; /* Add margin to create space */
    margin-left: 0px;
    padding-right: 0px;
  }
`;

const CallNowBox = styled.div`
  max-width: 300px;
  height: auto;
  background: #2f3630;
  border-radius: 10px;
  text-align: left;
  padding: 40px 30px;
  font-family: "Open Sans", sans-serif;
  margin-top: 60px;

  @media screen and (max-width: 1000px) {
    width: 100%; /* Make it full width on smaller screens */
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const CallNumber = styled.a`
  color: #b8d5aa;
  font-size: 30px;
  font-weight: 400;
  line-height: 1;

  position: relative;
  padding-right:  30px;
  cursor: pointer;
  text-decoration: none;

  &::after {
    padding-bottom:  15px;
    content: url("../images/arrow.svg");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: right ease 0.4s;
  }

  &:hover {
    opacity: 0.8;
    &::after {
      right: -10px;
    }
  }
  &:active {
    opacity: 0.5;
  }
`;


const CallNowText = styled.div`
  font-size: 24px;
  line-height: 1;
  color: #ffffff;
  padding: 5px;
`;

const ContactSubmitBox = styled.form`
  position: relative;
  height: 100%;
  width: 665px;
  max-width: 100%;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 16px 43px 0px #2f36303d;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;

  input::placeholder,
  textarea::placeholder {
    color: #5e8a64; /* Set your desired color here */
    opacity: 1; /* Optional: ensures full opacity on all browsers */
  }

  @media screen and (max-width: 1000px) {
    margin-top: 20px; /* Add margin to create space */
  }
`;

const ContactUs = styled.div`
  font-size: 28px;
  line-height: 1.27;
  color: black;
  padding: 15px;
`;

const ContactInput = styled.input`
  font-size: 18px;
  line-height: 1.27;
  width: 90%;
  border-radius: 6px;
  background: #5e8a641a;
  color: #5e8a64;
  padding: 15px;
  outline: none;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: start;
  align-items: start;
  pointer-events: auto;
  position: relative;
  font-family: Raleway;
`;

const ContactCheckbox = styled.input`
  font-size: 18px;
  font-family: Raleway;
  position: relative;
  left: -45%;
  top: 0px;
`;

const CheckboxLabel = styled.label`
  position: relative;
  margin: -16px 20px 0px 40px;
`;

const ContactSelect = styled.select`
  font-size: 18px;
  line-height: 1.27;
  width: 95%;
  border-radius: 6px;
  background: #5e8a641a;
  color: #5e8a64;
  padding: 15px;
  outline: none;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: start;
  align-items: start;
  pointer-events: auto;
  position: relative;
  font-family: Raleway;
`;

const MessageInput = styled.textarea`
  font-size: 18px;
  line-height: 1.27;
  min-height: 150px; /* Set a minimum height */
  width: 90%;
  border-radius: 6px;
  background: #5e8a641a;
  color: #5e8a64;
  padding: 15px;
  outline: none;
  border: none;
  resize: none; /* Allow vertical resizing */
  overflow-y: auto; /* Add scrollbar when needed */
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: start;
  align-items: start;
  pointer-events: auto;
  position: relative;
  font-family: Raleway;
`;
const SubmitButtonWrap = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  padding: 20px 12px;
`;

const SubmitButton = styled.button`
  margin-top: 10px;
  text-align: right;
  padding: 15px 50px;
  box-shadow: 0px 4px 4px 0px #2f363033;
`;

const ThankYouContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px, 50px;
  text-align: center;
`;

const ThankYouHeader = styled.div`
  font-family: Raleway;
  font-size: 42px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  padding: 40px;
  margin-top: 150px;
  @media screen and (max-width: 1000px) {
    margin-top: 50px;
  }
`;

const ThankYouBold = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
`;

const ThankYouText = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

const ThankYouWrap = styled.div`
  padding: 20px;
  margin-bottom: 150px;
  @media screen and (max-width: 1000px) {
    margin-bottom: 50px;
  }
`;

const Gap50 = styled.div`
  display: block;
  height: 50px;
`;

const IntroBandAuthor = styled.div`
  font-family: Raleway;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  margin-top: 15px;
`;

function buildEncodedEmailURL(formData) {
  // Base URL and the recipient's email address
  const baseURL =
    "https://sendemail-ewacs772ua-uc.a.run.app?";
  const recipientEmail = "info@zendo.health";

  // Map the JSON keys to the expected URL parameters
  const queryParams = {
    to: recipientEmail,
    name: formData.name,
    phone: formData.phone,
    email: formData.email,
    sms: formData.sms,
    message: formData.state + ': ' + formData.message,
  };

  // Filter out null or empty values
  const filteredParams = Object.keys(queryParams).reduce((acc, key) => {
    if (queryParams[key] !== null && queryParams[key] !== "") {
      acc[key] = queryParams[key];
    }
    return acc;
  }, {});

  // Build query string from the filtered params
  let queryString = Object.keys(filteredParams)
    .map((key) => {
      // Assuming all values are strings; if not, they need to be converted to strings
      let value = String(filteredParams[key]);
      return encodeURIComponent(key) + "=" + encodeURIComponent(value);
    })
    .join("&");

  // Return the full URL
  return baseURL + queryString;
}

async function sendEmail(encodedURL) {
  try {
    const response = await fetch(encodedURL);

    // Check if the request was successful
    if (response.ok) {
      // If the response status code is 200-299
      console.log("Email sent successfully!");
      return { status: "success", statusCode: response.status };
    } else {
      // If the response status code is not in the 200 range
      console.error("Failed to send email. Status:", response.status);
      return { status: "fail", statusCode: response.status };
    }
  } catch (error) {
    // If there is an error during fetch
    console.error("Error while sending email:", error);
    return { status: "error", error: error };
  }
}

function Contact() {
  const [submitted, setSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    state: "",
    message: "",
    sms: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailUrl = buildEncodedEmailURL(formData);

    const result = await sendEmail(emailUrl);

    console.log("result.status",result.status)
    if (result.status === "success") {
      setSubmitted(true);
    }
  };

  return (
    <FadeInOnScroll>
      <main>
        <div className="green-bg">
          <Hero>
            <Nav />
            <ContactLayout>
              <ContactLeft>
                <Title>{"Contact Us"}</Title>
                <Description>
                  {
                    "Take the next step in your journey!"
                  }
                </Description>
                <CallNowBox>
                  <CallNowText>{"Oregon"}</CallNowText>
                  <CallNumber href="tel:+15418180009">(541) 818-0009</CallNumber>
                  <br/><br/>
                  <CallNowText>{"Utah"}</CallNowText>
                  <CallNumber href="tel:+13855180652">(385) 518-0652</CallNumber>
                  <br/><br/>
                  <CallNowText>{"Kansas"}</CallNowText>
                  <CallNumber href="tel:+19132257850">(913) 225-7850</CallNumber>
                </CallNowBox>
              </ContactLeft>
              <ContactSubmitBox onSubmit={handleSubmit}>
                {submitted && (
                  <>
                    <ThankYouContent>
                      <ThankYouHeader>{"Thank You"}</ThankYouHeader>
                      <ThankYouWrap>
                        <ThankYouBold>
                          {"Your message has been sent."}
                        </ThankYouBold>
                        <ThankYouText>
                          {"If you requested that someone contact you,"}
                        </ThankYouText>
                        <ThankYouText>
                          {"expect to hear back within 1 business day."}
                        </ThankYouText>
                      </ThankYouWrap>
                    </ThankYouContent>
                  </>
                )}

                {!submitted && (
                  <>
                    <ContactUs>{"Send Email"}</ContactUs>
                    <ContactSelect
                      id="state"
                      size="3"
                      value={formData.state}
                      onChange={handleChange}
                      required
                    >
                      <option value="Oregon">I live in Oregon</option>
                      <option value="Utah">I live in Utah</option>
                      <option value="Kansas">I live in Kansas</option>
                    </ContactSelect>
                    <ContactInput
                      type="text"
                      id="name"
                      placeholder="Name*"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                    <ContactInput
                      type="email"
                      id="email"
                      placeholder="Email*"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <ContactInput
                        type="text"
                        id="phone"
                        placeholder="Phone*"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    <ContactCheckbox
                        type="checkbox"
                        id="sms"
                        value={formData.sms}
                        onChange={handleChange}
                      />
                    <CheckboxLabel for="sms">By checking this box you agree to receive SMS from Zendo Health for business purposes. Please review our <a target="_blank" href="https://zendo.health/files/privacy_policy.pdf">Privacy Policy</a> and <a target="_blank" href="https://zendo.health/files/terms_conditions.pdf">Terms</a></CheckboxLabel>
                    
                    <MessageInput
                      type="text"
                      id="message"
                      placeholder="Message*"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                    <SubmitButtonWrap>
                      <SubmitButton type="submit">Submit</SubmitButton>
                    </SubmitButtonWrap>
                  </>
                )}
              </ContactSubmitBox>
            </ContactLayout>
          </Hero>
          <IntroBand>
            <Gap50 />
            "Between stimulus and response there is a space. In that space is
            our power to choose our response. In our response lies our growth
            and our freedom."
            <IntroBandAuthor>- Viktor E. Frankl</IntroBandAuthor>
          </IntroBand>
        </div>
        <CallToAction />
      </main>
      <Footer />
    </FadeInOnScroll>
  );
}

export default Contact;
